import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
// import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import itcDataUpdation from "../itcDataUpdation";

export default {
  name: 'recoErrors',
  watch: {
    selectedLegalEntity: function() {
      this.parent_value_set_id = this.selectedLegalEntity.value;
    },
    currentPage: function() {
      this.getRecoErrors();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRecoErrors();
    }
  },
  data() {
    return {
      loading: false,
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      leName: {
        value: null,
        text: null
      },
      fpGstn: {
        value: null,
        text: null
      },
      glDateFrom: null,
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      glDateTo: null,
      selectedPeriodTo: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      recoErrorsData: [],
      recoErrorsFields: [
        {
          key: 'total_errors',
          variant: 'success',
          class: 'col-expand-large'
        },
        {
          key: 'vendor_name',
          class: 'text-center'
        },
        {
          key: 'period_name',
          class: 'col-fix text-center'
        },
        {
          key: 'fp_gst',
          label: 'FP GST',
          class: 'col-fix text-center'
        },
        {
          key: 'tp_gst',
          label: 'TP GST',
          class: 'col-fix text-center'
        },
        {
          key: 'invoice_num',
          class: 'col-fix text-center'
        },
        {
          key: 'doc_sequence_value',
          class: 'col-fix text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'col-fix text-center'
        },
        {
          key: 'invoice_date',
          class: 'col-fix text-center'
        },
        {
          key: 'rate',
          class: 'col-fix text-center'
        },
        {
          key: 'recoverable_flag',
          class: 'col-fix text-center'
        },
        {
          key: 'rev_charge',
          class: 'col-fix text-center'
        },
        {
          key: 'invoice_amt',
          class: 'col-fix text-center'
        },
        {
          key: 'taxable_value',
          class: 'col-fix text-center'
        },
        {
          key: 'total_tax',
          class: 'col-fix text-center'
        },
        {
          key: 'igst',
          label: 'IGST',
          class: 'd-total-rec col-fix text-center',
          variant: 'success'
        },
        {
          key: 'cgst',
          label: 'CGST',
          class: 'd-total-rec col-fix text-center',
          variant: 'success'
        },
        {
          key: 'sgst',
          label: 'SGST',
          class: 'd-total-rec col-fix text-center',
          variant: 'success'
        }
      ],
      downloadPayload: null,
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      checked: 'Summarized',
      viewMoreFlag: false,
      invoiceNumber: null,
      tpGstn: null,
      vendorName: null,
      voucherNumber: null,
      showUpdationModal: false,
      minimizeFlag: true,
      searchRequestParams: {},
      correctionFlag: {
        value: null,
        text: null
      }
    };
  },
  components: {
    DatePicker,
    itcDataUpdation
  },
  validations: {
    leName: {
      value: {
        required
      }
    },
    selectedPeriodFrom: {
      value: {
        required
      }
    },
    selectedPeriodTo: {
      value: {
        required
      }
    }
    // glDateFrom: { required },
    // glDateTo: { required }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loading = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          if (this.checked === 'Summarized') {
          const downloadpayload = { ...this.downloadPayload };
            downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'gstrTwoADownload/recoErrors',
            'Reco Errors',
            () => (this.loading = false)
          );
        } else if (this.checked === 'Detailed') {
          const downloadpayload = { ...this.downloadPayload };
            downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'gstrTwoADownload/recoErrorsSummarized',
            'Reco Errors Detailed',
            () => (this.loading = false)
          );
        }
      }
      }
    });
  },
  methods: {
    // downloadExcelData() {
    //   this.loading = true;
    //   const downloadpayload = { ...this.downloadPayload };
    //   downloadpayload._limit = this.totalRows;
    //   this.downloadExcel.downloadData(
    //     downloadpayload,
    //     'gstrTwoADownload/recoErrorsSummarized',
    //     'Reco Errors summarized',
    //     () => (this.loading = false)
    //   );
    // },
    getRecoErrors() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value,
        fp_gst: this.fpGstn.value,
        tp_gst: this.tpGstn,
        invoice_num: this.invoiceNumber,
        vendor_name: this.vendorName,
        doc_sequence_value: this.voucherNumber,
        correction_flag: this.correctionFlag.value
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.$store
        .dispatch('gstrTwoADownload/recoErrors', payload)
        .then(response => {
          if (response.status === 200) {
            this.showAlert();
            this.recoErrorsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getRecoErrorsSummarized() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value,
        fp_gst: this.fpGstn.value,
        tp_gst: this.tpGstn,
        invoice_num: this.invoiceNumber,
        vendor_name: this.vendorName,
        doc_sequence_value: this.voucherNumber,
        correction_flag: this.correctionFlag.value
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.$store
        .dispatch('gstrTwoADownload/recoErrorsSummarized', payload)
        .then(response => {
          if (response.status === 200) {
            this.showAlert();
            this.recoErrorsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openValueSetModal(vsetCode, periodType, valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.valueType = valueType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD ||
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.fpGstn = {
          value: item.value_code,
          text: item.gst_number
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.ZERO_ONE
      ) {
        this.correctionFlag = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      this.name = item.name;
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, periodType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        }
      } else if (vsetCode === this.invoiceNumber) {
        this.invoiceNumber = null;
      } else if (vsetCode === this.tpGstn) {
        this.tpGstn = null;
      } else if (vsetCode === this.vendorName) {
        this.vendorName = null;
      } else if (vsetCode === this.voucherNumber) {
        this.voucherNumber = null;
      } else if (vsetCode === this.correctionFlag.value) {
        this.correctionFlag = this.defaultValue;
      } else if (vsetCode === this.fpGstn.value) {
        this.fpGstn = {
          value: null,
          text: null
        };
      }
    },
    clear() {
      this.leName = this.defaultValue;
      this.glDateFrom = null;
      this.glDateTo = null;
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'total_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-rec', class2);
          });
        }
      } else {
        if (data === 'total_tax') {
          let otherElem = document.querySelectorAll('.other-total-rec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rec', 'd-total-rec');
          });
        }
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    changeTable() {
      if (this.checked === 'Detailed') {
        this.getRecoErrorsSummarized();
        this.recoErrorsFields = [
          {
            key: 'total_errors',
            variant: 'success',
            class: 'col-expand-large'
          },
          {
            key: 'invoice_amt',
            class: 'col-fix text-center'
          },
          {
            key: 'taxable_amt',
            class: 'col-fix text-center'
          },
          {
            key: 'total_tax',
            class: 'col-fix text-center'
          },
          {
            key: 'no_of_voucher',
            class: 'col-fix text-center'
          },
        ];
      } else {
        this.getRecoErrors();
        this.recoErrorsFields = [
          {
            key: 'total_errors',
            variant: 'success',
            class: 'col-expand-large'
          },
          {
            key: 'period_name',
            class: 'col-fix text-center'
          },
          {
            key: 'fp_gst',
            label: 'FP GST',
            class: 'col-fix text-center'
          },
          {
            key: 'tp_gst',
            label: 'TP GST',
            class: 'col-fix text-center'
          },
          {
            key: 'invoice_num',
            class: 'col-fix text-center'
          },
          {
            key: 'doc_sequence_value',
            class: 'col-fix text-center'
          },
          {
            key: 'gl_date',
            label: 'GL Date',
            class: 'col-fix text-center'
          },
          {
            key: 'invoice_date',
            class: 'col-fix text-center'
          },
          {
            key: 'rate',
            class: 'col-fix text-center'
          },
          {
            key: 'recoverable_flag',
            class: 'col-fix text-center'
          },
          {
            key: 'rev_charge',
            class: 'col-fix text-center'
          },
          {
            key: 'invoice_amt',
            class: 'col-fix text-center'
          },
          {
            key: 'taxable_value',
            class: 'col-fix text-center'
          },
          {
            key: 'total_tax',
            class: 'col-fix text-center'
          },
          {
            key: 'cgst',
            label: 'CGST',
            class: 'd-total-rec col-fix text-center',
            variant: 'success'
          },
          {
            key: 'sgst',
            label: 'SGST',
            class: 'd-total-rec col-fix text-center',
            variant: 'success'
          },
          {
            key: 'igst',
            label: 'IGST',
            class: 'd-total-rec col-fix text-center',
            variant: 'success'
          }
        ];
      }
    },
    viewMoreFilters() {
      this.viewMoreFlag = !this.viewMoreFlag;
    },
    rowSelected(item) {
      this.searchRequestParams = {
        leName : this.leName,
        selectedPeriodFrom: this.selectedPeriodFrom,
        selectedPeriodTo: this.selectedPeriodTo,
        rowData: item
      }
      this.showUpdationModal = true;
    },
    updationModal(flag) {
      this.showUpdationModal = flag;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
